import React, { useEffect } from "react";
import { Button, Checkbox, message, Input } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "assets/logo.png";
import config from "config";
import axios from "axios";

class Forgot extends React.Component {
  state = {
    email: "",
    sent: false,
  };

  componentDidMount() {
    console.log("is auth");
    console.log(this.props.auth);
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.role == "admin") {
        this.props.history.push("/admin/dashboard");
      } else if (this.props.auth.user.role == "doctor") {
        //   console.log(this.props.auth.user)
        this.props.history.push("/doctor/dashboard");
      } else {
        this.props.history.push("/sale/dashboard");
      }
    }
  }
  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  emailChange = (e) => {
    axios
      .post(`${config.API_URL}/auth/forgotpassword`, {
        email: this.state.email,
      })
      .then((response) => {
        // message.success("Email Sent Please Check Your Email");
        // this.setState({ email: "", sent: true });

        if (response.data.success == false) {
          message.error("Email Not Sent");
        } else {
          message.success("Email Sent Please Check Your Email");
          this.setState({ email: "", sent: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="gx-app-login-wrap" style={{ marginTop: "15%" }}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img
                  src="https://s3.us-east-1.wasabisys.com/constructions/static/logo.svg"
                  alt="Neature"
                />
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>

            <div className="gx-app-login-content">
              <div
                style={{ padding: 15, marginTop: 20 }}
                className="form-group"
              >
                <Input
                  type="email"
                  placeholder="Enter Your Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleOnChange}
                />
              </div>
              <div style={{ padding: 15, marginLeft: 70 }}>
                <Button type="primary" onClick={this.emailChange}>
                  Send Email
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authR,
});
export default connect(mapStateToProps)(Forgot);
